import React from "react";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import { API } from "api";

interface IUserData {
  time: number;
  ip: string;
  user: {
    name: string;
  };
}

type Props = {};

export const UserTable = ({ }: Props) => {
  const { data = [], isLoading } = useQuery("userStatTable", () => {
    return API.get("/analytic", {
      params: {
        query: {
          distinct: ["ip"],
          where: {
            category: "channel_view",
          },
          include: {
            user: true,
          },
        },
      },
    }).then((response) => response.data);
  });
  
  return (
    <TableContainer>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>用户名</Th>
            <Th>IP</Th>
            {/* <Th isNumeric>观看时间</Th> */}
          </Tr>
        </Thead>
        <Tbody>
          {[{ time: 12, ip: "192.168.23.45", user: { name: "Tony" } },
          { time: 12, ip: "172.16.89.123", user: { name: "Olivia" } },
           { time: 12, ip: "172.31.255.254", user: { name: "Hands" } }, 
           { time: 12, ip: "192.168.100.200", user: { name: "Donald" } }, 
           { time: 12, ip: "192.168.0.1", user: { name: "Raymond" } }].map((item: IUserData) => (
            <Tr key={item.ip}>
              <Td>{item.user?.name || "Guest"}</Td>
              <Td>{item.ip}</Td>
              {/* <Td isNumeric>{item.time}m</Td> */}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
