import React, { ChangeEvent, useCallback, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Button,
  Text,
  Textarea,
  Switch,
  HStack,
} from "@chakra-ui/react";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import { useQueryClient } from "react-query";
import { IEvent } from "type";
import { EventService } from "module/auth/service/EventService";
import { FormattedMessage, useIntl } from "react-intl";
import { sendCommonGAEvent } from "util/analytic";

interface EditEventModalProps {
  isActive: boolean;
  event: IEvent;
  onClose: () => void;
}

const EditEventModal: React.FC<EditEventModalProps> = ({
  isActive,
  event,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const [title, setTitle] = useState(event.title);
  const [description, setDescription] = useState(event.description);
  const [startTime, setStartTime] = useState(new Date(event.start_at));
  const [endTime, setEndTime] = useState(new Date(event.end_at));
  const [isEventStartWithStream, setIsEventStartWithStream] = useState(
    event.is_start_with_stream
  );

  const handleSave = useCallback(async () => {
    let requestData: any = {};
    if (title !== event.title) requestData["title"] = title;
    if (description !== event.description)
      requestData["description"] = description;
    if (startTime !== event.start_at) requestData["start_at"] = startTime;
    if (endTime !== event.end_at) requestData["end_at"] = endTime;
    if (isEventStartWithStream !== event.is_start_with_stream)
      requestData["is_start_with_stream"] = isEventStartWithStream;
    await EventService.updateEvent(event.channel_id, event.id, requestData);
    sendCommonGAEvent({
      category: "edit_event",
      action: "send",
      eventProps: {
        channel_id: event.channel_id,
      },
    });
    await queryClient.invalidateQueries("GetChannelEvents");
    onClose();
  }, [
    onClose,
    queryClient,
    event,
    title,
    description,
    startTime,
    endTime,
    isEventStartWithStream,
  ]);

  const handleDateTimeRangeClick = useCallback((dateRange: any) => {
    setStartTime(dateRange[0]);
    setEndTime(dateRange[1]);
  }, []);

  return (
    <Modal isOpen={true} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage id="edit" />{" "}
          {isActive ? (
            <FormattedMessage id="dashboard.liveShow.underway" />
          ) : (
            ""
          )}
          <FormattedMessage id="dashboard.liveShow.liveShow" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{formatMessage({ id: "dashboard.liveShow.new.title" })}</Text>
          <Input
            placeholder={formatMessage({ id: "dashboard.liveShow.new.title" })}
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            mb="16px"
          />
          <Text>
            {formatMessage({ id: "dashboard.liveShow.new.description" })}
          </Text>
          <Textarea
            placeholder={formatMessage({
              id: "dashboard.liveShow.new.description",
            })}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            mb="16px"
          />
          <Text>{formatMessage({ id: "dashboard.liveShow.new.title" })}</Text>
          <DateTimeRangePicker
            value={[startTime, endTime]}
            onChange={handleDateTimeRangeClick}
          />
          <HStack mt="16px">
            <Switch
              checked={isEventStartWithStream}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setIsEventStartWithStream(event.target.checked);
              }}
            />
            <Text>
              {formatMessage({ id: "dashboard.liveShow.new.startWithStream" })}
            </Text>
          </HStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <Button colorScheme="blue" onClick={handleSave}>
            <FormattedMessage id="button.save" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditEventModal;
