import React, { useEffect } from "react";
import { Flex, useColorModeValue, Box } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";
import {  Outlet } from "react-router";
import { DashboardSidebar } from "./DashboardSidebar";

export const Dashboard = observer(() => {
  const { uiStore, authStore } = useStore();

  useEffect(() => {
    if (!authStore.isLoggedIn) {
      uiStore.setAuthModalType("login");
      uiStore.toggleAuthModalOpen();
    }
  }, [authStore.isLoggedIn, uiStore]);

  return (
    <>
      {!authStore.isLoggedIn && <Box w="full" h="full" position="absolute" zIndex={1} bg="gray" opacity={0.4}  />}
      <Flex w="full" h={'calc(100vh - 120px)'} flexDirection={"row"} overflow="hidden">
        <DashboardSidebar />
        <Flex
          flex={1}
          align={"center"}
          justify={"center"}
          bg={useColorModeValue("gray.50", "gray.800")}
          h="100%"
        >
          <Outlet />
        </Flex>
      </Flex>
    </>
  );
});
